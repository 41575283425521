/* eslint-disable linebreak-style */
// import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import omc from 'src/utils/omnicommerce/omc-ready-to-use';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PASSWORD = '@account/update-password';


export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  console.log("update.data: ", update.data);
  const request = omc.patch('/usuarios/' + update.id, update.data)
  .then(() => {
    return omc.get('/usuarios/' + update.id)
  })

  return (dispatch) => {
    request
    .then((response) => {
      console.log({response});
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          user: response.body.data,
          id: update.id
        }
      })
  });
  };
}

export function updatePassword(update) {
  const request = omc.post(`/usuarios/${update.id}/cambiar-clave`, {
    claveNueva: update.data.password,
    claveNuevaConfirmacion: update.data.passwordConfirm
  });

  return (dispatch) => {
    request
      .then((response) => {

        if (!response.body.status) {
          /*
          dispatch({
            type: UPDATE_PASSWORD_FAILED,
            payload: {
              result: false,
              message: response.body.message
            }
          })
          */
        }

        console.log(response);

        dispatch({
          type: UPDATE_PASSWORD,
          payload: {
            result: true,
            id: update.id
          }
        })
      })
      .catch((error) => {
        /*
          dispatch({
            type: UPDATE_PASSWORD_FAILED,
            payload: {
              result: false,
              message: response.body.message
            }
          })
        */
      });
  }
};