/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */

import produce from 'immer';
import { GET_NOTIFICATIONS, ARCHIVE_NOTIFICATION, MARCAR_NOTIFICATION } from 'src/actions/notificationsActions';

const initialState = {
  notifications: []
};

const notificationsReducer = (state = initialState, action) => {
  if (action.type === GET_NOTIFICATIONS) {
    const { notifications } = action.payload;

    return produce(state, (draft) => {
      draft.notifications = notifications;
    });
  }
  if (action.type === ARCHIVE_NOTIFICATION) {
    const { idNotification: idNotificationArchivar, archivada } = action.payload;
    return produce(state, (draft) => {
      draft.notifications = draft.notifications.map((notification) => {
        if (notification.id === idNotificationArchivar) {
          notification.archivada = archivada;
        }
        return notification;
      });
    });
  }
  if (action.type === MARCAR_NOTIFICATION) {
    const { idNotification: idNotificationMarcar, vista } = action.payload;
    return produce(state, (draft) => {
      draft.notifications = draft.notifications.map((notification) => {
        if (notification.id === idNotificationMarcar) {
          notification.vista = vista;
        }
        return notification;
      });
    });
  }
  return state;
};

export default notificationsReducer;
