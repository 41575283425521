/* eslint-disable prefer-object-spread */
/* eslint-disable linebreak-style */
import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  makeStyles,
  styled
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  AlertTriangle as AlertTriangleIcon,
  PauseCircle as PauseCircleIcon,
  X as XIcon,
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
  CloudOff as CloudOffIcon,
  CloudLightning as CloudLightningIcon,
  CloudRain as CloudRainIcon,
  Bell as BellIcon,
  Filter as FilterIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  ShoppingCart as ShoppingCartIcon,
  Truck as TruckIcon,
  Shuffle as ShuffleIcon,
  X as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Trash2 as TrashFullIcon
} from 'react-feather';
import {
  archiveNotification,
  getNotifications,
  marcarNotification
} from 'src/actions/notificationsActions';
import OneSignal from 'react-onesignal';
import Confetti from 'src/components/Confetti';

const preventDefault = (event) => {
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

const iconsMap = {
  default: { class: 'notify_info', icon: BellIcon },
  default_info: { class: 'notify_info', icon: BellIcon },
  default_success: { class: 'notify_success', icon: CheckCircleIcon },
  default_warning: { class: 'notify_warning', icon: AlertTriangleIcon },
  default_error: { class: 'notify_error', icon: AlertCircleIcon },
  default_critical: { class: 'notify_critical', icon: AlertCircleIcon },
  'productos.syncro.in.exito': { class: 'notifyInfo', icon: CheckCircleIcon },
  'producto.syncro.in.fallo': { class: 'notifyWarning', icon: AlertCircleIcon },

  'producto.syncro.in.aviso.not-found': { class: 'notifyWarning', icon: PauseCircleIcon },
  'producto.syncro.in.aviso.variant-not-found': { class: 'notifyWarning', icon: PauseCircleIcon },
  'producto.syncro.in.aviso.multiple-sku-product': { class: 'notifyWarning', icon: ShuffleIcon },

  'pedido.syncro.in.info.customer-email-error': { class: 'notifyWarning', icon: AlertTriangleIcon },
  'pedido.syncro.in.info.cuit-erroneo': { class: 'notifyWarning', icon: AlertTriangleIcon },

  'pedido.syncro.in.fallo.sku-missing': { class: 'notifyInfo', icon: FilterIcon },
  'pedido.syncro.in.fallo.sku-mismatch': { class: 'notifyWarning', icon: TrashFullIcon },
  'pedido.syncro.in.fallo.sku-mismatch-retry': { class: 'notifyWarning', icon: AlertCircleIcon },
  'pedido.syncro.in.fallo.fecha-corte-threshold-exceeded': { class: 'notifyWarning', icon: TrashFullIcon },
  'pedido.syncro.out.fallo': { class: 'notifyWarning', icon: AlertTriangleIcon },
  'pedido.syncro.out.fallo.negative-stock': { class: 'notifyWarning', icon: PackageIcon },
  'pedido.syncro.out.fallo.no-retry': { class: 'notifyProblem', icon: AlertCircleIcon },

  'stock.syncro.in.exito': { class: 'notifyInfo', icon: CheckCircleIcon },
  'stock.syncro.in.fallo': { class: 'notifyWarning', icon: AlertCircleIcon },
  'stock.syncro.in.fallo.no-warehouse': { class: 'notifyProblem', icon: AlertCircleIcon },
  'stock.syncro.in.fallo.no-prices-list': { class: 'notifyProblem', icon: AlertCircleIcon },
  'stock.syncro.in.fallo.connection-failed': { class: 'notifyWarning', icon: CloudRainIcon },

  'conexion.desactivada': { class: 'notifyProblem', icon: CloudOffIcon },
  'conexion.reactivada': { class: 'notifySuccess', icon: CloudLightningIcon },

  'customer-server.connection-failed': { class: 'notifyWarning', icon: CloudOffIcon },
  'customer-server.self-hosted-api.error': { class: 'notifyProblem', icon: CloudLightningIcon },

  'pedido.creado': { class: 'notifyInfo', icon: ShoppingCartIcon },
  new_message: { class: 'notifyInfo', icon: MessageIcon },
  item_shipped: { class: 'notifyInfo', icon: TruckIcon },
};

const useStyles = makeStyles((theme) => ({
  badge: {
    marginTop: 10,
    marginRight: 5,
    backgroundColor: '#ee0000',
  },
  popover: {
    width: 420
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  hide: {
    display: 'none',
  },
  /*notifyProblem: {
    color: '#ce6060',
    backgroundColor: 'transparent',
  },
  notifyWarning: {
    color: '#ff800d',
    backgroundColor: 'transparent',
  },
  notifySuccess: {
    color: '#00be90',
    backgroundColor: 'transparent',
  },
  notifyInfo: {
    color: '#ccc',
    backgroundColor: 'transparent',
  },*/
  notify_critical: {
    color: '#ce6060',
    backgroundColor: 'transparent',
  },
  notify_error: {
    color: '#ce6060',
    backgroundColor: 'transparent',
  },
  notify_warning: {
    color: '#ff800d',
    backgroundColor: 'transparent',
  },
  notify_success: {
    color: '#00be90',
    backgroundColor: 'transparent',
  },
  notify_info: {
    color: '#ccc',
    backgroundColor: 'transparent',
  }
}));

let mostrarPrimeraNotificacion = false;
function Notifications() {
  const classes = useStyles();
  //El [...state.notifications.notifications] es necesario para poder hacer el sort de pin 
  const notifications = useSelector((state) => [...state.notifications.notifications]);

  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isSubscribed, setSubscribed] = useState(null);

  const manejarDetalles = (detalles) => {
    //Si contiene HTML
    if (detalles.includes('<') && detalles.includes('>')) {
      return <div dangerouslySetInnerHTML={{ __html: detalles }} />;
    }
    return `\n\nMás info: \n${detalles}`
  }

  const account = useSelector((state) => state.account);

  const [openNotification, setOpenNotification] = React.useState(false);

  const [activarConfeti, setActivarConfeti] = useState(0);

  const handleClickOpenNotification = (notificationObject) => {
    if (notificationObject.metadata.confetti) {
      setActivarConfeti(activarConfeti + 1);
    }
    setOpenNotification(notificationObject);
    dispatch(marcarNotification(notificationObject.id, 1));
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
    setActivarConfeti(0);
  };
  const handleArchiveNotification = (e, notificationObject) => {

    preventDefault(e)

    dispatch(archiveNotification(notificationObject.id));
  };
  const handleVistaNotification = (e, notificationObject) => {
    preventDefault(e)
    dispatch(marcarNotification(notificationObject.id, !notificationObject.vista));
  };

  const handleOpen = () => {
    setOpen(true);
    OneSignal.isPushNotificationsEnabled()
      .then(r => {
        setSubscribed(r)
      })
      .catch(e => console.warn('Error al obtener el estado de las notificaciones push'))
  };

  const handleClose = () => {
    setOpen(false);
  };

  const registerForPushNotifications = () => {
    OneSignal.registerForPushNotifications()
      .then(r => {
        OneSignal.isPushNotificationsEnabled()
          .then(r => {
            setSubscribed(r)
          })
          .catch(e => console.warn('Error al obtener el estado de las notificaciones push'))
      })
      .catch(e => {
        console.log(e)
      })
  }

  setTimeout(() => {
    OneSignal.isPushNotificationsEnabled()
      .then(r => {
        setSubscribed(r)
      })
      .catch(e => console.warn('Error al obtener el estado de las notificaciones push'))
  }, 10000);

  useEffect(() => {
    setInterval(() => {
      if (account.user) {
        dispatch(getNotifications());
      }
    }, 60000);
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (!mostrarPrimeraNotificacion && notifications.length) {
      
      mostrarPrimeraNotificacion = true;
      const notification = notifications.find((n) => n.metadata?.autoOpen && !n.vista);
      
      if (notification) {
        handleClickOpenNotification(notification);
      }
    }
  }, [notifications]);

  return (
    <>
      <Tooltip title="Notificaciones">

        <Badge
          color="secondary"
          classes={{ badge: classes.badge }}
          badgeContent={(notifications.filter((notification) => !notification.vista).length + (isSubscribed === false ? 1 : 0))}
        >
          <IconButton
            color="inherit"
            ref={ref}
            onClick={handleOpen}
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notificaciones
          </Typography>
        </Box>
        <Box
          p={1}
          display="flex"
          justifyContent="center"
          className={isSubscribed && classes.hide}
        >
          <Button
            onClick={() => { registerForPushNotifications() }}
            size="small"
            color="secondary"
            variant="contained"
            endIcon={<BellIcon />}
          >
            Activar las notificaciones
          </Button>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              No hay notificaciones
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.sort((a, b) => Number(b.pin) - Number(a.pin)).filter((notification) => !notification.archivada).map((notification) => {
                const Icon = (iconsMap[notification.codigo] && iconsMap[notification.codigo].icon) || iconsMap[`default_${notification.tipo}`].icon;

                if (notification.metadata?.topBar) {
                  return null
                }

                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to="#"
                    onClick={() => handleClickOpenNotification(notification)}
                    style={

                      (notification.metadata.class?.includes('omnicommerce')
                        ? {
                          background: (notification.vista ? 'linear-gradient(45deg, #0856CB, #ffa4d8)' : 'transparent')
                        }
                        : {
                          filter: (notification.vista ? 'opacity(0.65)' : ''),
                          backgroundColor: (notification.vista ? '#f8f8f8' : 'transparent')
                        }
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes[`notify_${notification.tipo}`]}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <>
                          <Typography
                            variant="subtitle2"
                            color="textPrimary"
                            style={
                              notification.metadata.class?.includes('omnicommerce') && {
                                color: 'white'
                              }
                            }
                          >
                            {`${notification.titulo} `}
                            {notification.vista
                              ? (
                                <Chip
                                  size="small"
                                  label="leída"
                                  style={
                                    notification.metadata.class?.includes('omnicommerce') && {
                                      color: 'white'
                                    }
                                  }
                                />
                              )
                              : ''
                            }
                          </Typography>
                          <Tooltip title={`Marcar como ${notification.vista ? 'no leída' : 'leída'}`}>
                            <IconButton
                              style={{ position: 'absolute', right: '23px', top: '-8px', opacity: 0.5, display: 'none' }}
                              onClick={(e) => handleVistaNotification(e, notification)}
                            >
                              <SvgIcon style={{ fontSize: "16px" }}>
                                {
                                  notification.vista ? (
                                    <EyeOffIcon style={{ display: 'none' }} />
                                  ) : (
                                    <EyeIcon style={{ display: 'none' }} />
                                  )
                                }
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Archivar">
                            <IconButton
                              style={{ position: 'absolute', right: '1px', top: '-4px', opacity: 0.5 }}
                              onClick={(e) => handleArchiveNotification(e, notification)}
                            >
                              <SvgIcon style={{ fontSize: "16px" }}>
                                <XIcon />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      style={{ whiteSpace: 'pre-line' }}
                      secondary={(

                        <Typography
                          style={{
                            color: notification.metadata.class?.includes('omnicommerce') ? 'white' : (notification.vista ? '#546e7a' : '#546e7a')
                          }}
                        >
                          {notification.texto}

                          <br />
                          <Tooltip title={notification.fechaHoraLastNotsoFriendly}>
                            <Typography style={Object.assign(
                              { width: 'fit-content', display: 'inline', fontSize: '0.85em', filter: 'opacity(0.6)', letterSpacing: 0 },
                              notification.metadata.class ? { color: 'white' } : {}

                            )} >
                              {`${notification.fechaHoraLastFriendly[0].toUpperCase()}${notification.fechaHoraLastFriendly.substring(1)}`}
                            </Typography>
                          </Tooltip>
                          <Typography style={Object.assign(
                            { width: 'fit-content', display: 'inline', fontSize: '0.85em', filter: 'opacity(0.6)', letterSpacing: 0 },
                            notification.metadata.class ? { color: 'white' } : {}
                          )} >
                            {(notification.cantidad > 1 ? ` - Esto ha ocurrido ${notification.cantidad} veces` : '')}
                          </Typography>

                        </Typography>
                      )}
                    />

                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              style={{ display: 'none' }}
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
              >
                Marcar como le&iacute;das
              </Button>
            </Box>
          </>
        )}
      </Popover>
      <Confetti activar={activarConfeti} />
      <Dialog
        BackdropProps={{
          style: openNotification?.metadata?.confetti && {
            backgroundColor: 'transparent'
          }
        }}
        open={(openNotification ? true : false)}
        onClose={handleCloseNotification}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {openNotification.fechaHoraFriendly} - {openNotification.titulo}
        </DialogTitle>
        <DialogContent dividers={true}>
          {
            (openNotification.metadata?.isHtml)
              ? (
                <div dangerouslySetInnerHTML={{ __html: openNotification.detalles }} />
              )
              : (
                <>
                  <Typography variant="h4">{openNotification.titulo}</Typography>
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {'\n'}
                    {openNotification.texto}
                    {(openNotification.detalles ?  manejarDetalles(openNotification.detalles) : '')}
                    {(openNotification.cantidad > 1 ? `\n\n Esto ha ocurrido ${openNotification.cantidad} veces.\nLa primera vez fue ${openNotification.fechaHoraCompleteFriendly}.` : '')}
                    {(typeof openNotification.metadata === 'object' ? `\n\nDatos adicionales: ${openNotification.metadataFriendly}` : '')}
                    {('\n')}
                    {openNotification.url && (
                      <RouterLink to={{ pathname: openNotification.url.replace('https://omnicommerce.app/', 'http://localhost:3000/') }} target="_blank">Link</RouterLink>
                    )}
                  </DialogContentText>
                </>
              )
          }


        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotification} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Notifications;
