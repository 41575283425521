'use strict'
import OmnicommerceRestApi from './omnicommerce-rest-api';

let OmcConfig = Object.assign(
  { endpoint : process.env.REACT_APP_API_URL },
  (localStorage.getItem('accessToken') ? { accessToken : localStorage.getItem('accessToken') } : null),
  (localStorage.getItem('refreshToken') ? { refreshTokenS : localStorage.getItem('refreshToken') } : null)
);

let omc = new OmnicommerceRestApi(OmcConfig);

const idRemoto = {

  get : function(conexion, modelo, id = null){
    let promise = new Promise(function(resolve, reject) {
      omc.Entity.Match.findOne({ where : {
        tenantKey : conexion.tenantKey,
        plataformaId : conexion.plataformaId,
        conexionId : conexion.id,
        modelo : modelo,
        modeloId : id
      }}, { tenantKey : conexion.tenantKey })
      .then(r => {
        resolve((r) ? r.idRemoto : null);
      })
      .catch(e => reject(e));
    });
    return promise;
  },

  getByIdRemoto : function(conexion, modelo, idRemoto){
    let promise = new Promise(function(resolve, reject) {
      omc.Entity.Match.findOne({ where : {
        tenantKey : conexion.tenantKey,
        plataformaId : conexion.plataformaId,
        conexionId : conexion.id,
        modelo : modelo,
        idRemoto : idRemoto
      }}, { tenantKey : conexion.tenantKey })
      .then(r => {
        resolve((r) ? r.id : null);
      })
      .catch(e => reject(e));
    });
    return promise;
  },

  set : (conexion, modelo, id = null, idRemoto, matchBy = null) => {
    let promise = new Promise(function(resolve, reject) {
      omc.Entity.Match.findOrCreate({ where : {
        tenantKey : conexion.tenantKey,
        plataformaId : conexion.plataformaId,
        conexionId : conexion.id,
        modelo : modelo,
        modeloId : id
      }, defaults : { idRemoto : idRemoto, matchBy : matchBy }}, { tenantKey : conexion.tenantKey })
      .then(r => {
        if(!r[1]){
          omc.Entity.Match.update({ idRemoto : idRemoto, matchBy : matchBy }, { where : { id : r.id }}, { tenantKey : conexion.tenantKey })
          .catch(e => reject(e));
        }
          resolve(idRemoto);
      })
      .catch(e => reject(e));
    });
    return promise;
  }
}

let conexiones = {

  setParametros : async (conexion, parametros) => {
    let promise = new Promise(function(resolve, reject) {
      let parametrosWork = [];
      for (var p in parametros) {
        if (parametros.hasOwnProperty(p)) {
          let valor = parametros[p];
          if(typeof parametros[p] === 'object' && parametros[p] !== null){
            valor = JSON.stringify(parametros[p]);
          }
          switch(valor){
            case true:
              valor = 'true';
              break;
            case false:
              valor = 'false';
              break;
            default:
              break;
          }
          parametrosWork.push(omc.Entity.ConexionParametro.findOrCreate({
            where : {
              tipo : 'tenant',
              plataformaId : conexion.plataformaId,
              conexionId : conexion.id,
              nombre : p
            },
            defaults : { nombre : p, valor : valor }}, { tenantKey : conexion.tenantKey }));
        }
      }
      Promise.all(parametrosWork)
      .then(r => resolve(true))
      .catch(e => {
        console.log(e);
        reject(false);
      })
    });
    return promise;
  },

  findParam : async (conexion, param) => {
    let paramRecord = conexion.parametros.filter(x => x.nombre == param);
    if(paramRecord[0]){
      return paramRecord[0].valor;
    }
    else{
      return null;
    }
  },
}

omc.idRemoto = idRemoto;
omc.conexiones = conexiones;

export default omc;
