/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography,
  Button
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
// import Search from './Search';
import Settings from './Settings';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveNotification
} from 'src/actions/notificationsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {},
    ...(process.env.ARGENTINA_FLAG ? {
      color: '#05c',
      backgroundColor: '#263238',
      background: 'url(https://t4.ftcdn.net/jpg/04/95/88/49/360_F_495884938_T7BeVBdGT1OC2nKUCp4iixLnaHKu3sBc.jpg) center no-repeat',
      backgroundSize: 'cover',
    } : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const notifications = useSelector((state) => [...state.notifications.notifications]);

  useEffect(() => {
  }, [notifications])

  //Saber si estoy en la pag de chat
  const location = window.location.pathname;
  const isChat = location.includes('chat');


  const dispatch = useDispatch();
  return (<>

    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      {
        notifications.length > 0 ? notifications.map((notification) => {
          if (!notification.metadata?.topBar || notification.archivada) return

          return (
            <div style={{
              width: '100%',
              zIndex: 9999,
              background: notification.tipo === "error" ? '#c53929' : (notification.tipo === "warning" ? '#f57c00' : '#888'),
              color: '#fff',
              padding: '10px 20px',
              position: 'absolute',
              textAlign: 'center',
            }} key={notification.id}>

              <Typography variant="body1">
                <span style={{ fontWeight: 400 }}>{notification.titulo} </span>
                {' - '}
                {(notification.metadata?.isHtml)
                  ? (
                    <div dangerouslySetInnerHTML={{ __html: notification.detalles }} />
                  )
                  : <span style={{ fontWeight: 100 }}>{notification.texto} </span>}


              </Typography>
              <Typography
                variant="body1"
                style={{ color: '#c3d9ff', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  dispatch(archiveNotification(notification.id))
                }}
              >
                Descartar
              </Typography>
            </div>)
        }) : (
          <></>
        )
      }
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        {
          process.env.NODE_ENV !== 'production' ? (
            <Hidden lgUp>
          {/* postion in the middle*/}
          <RouterLink to="/" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <Logo />
          </RouterLink>
        </Hidden>
          ) : <></>
        }
        
        <Box
          ml={2}
          flexGrow={1}
        />
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  </>);
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
