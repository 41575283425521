import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const account = useSelector((state) => state.account);
  if (account.user) {
    const queryParameters = new URLSearchParams(window.location.search)
    const redir = queryParameters.get("redir")
    if (redir) {
      const rt = localStorage.getItem('refreshToken')
      window.location.href = `${redir.replace('[RT]',rt)}`;
    }
    else{
      return <Redirect to="/app/account" />;
    }
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
