/* eslint-disable linebreak-style */
'use strict'
import Naquelize from './omnicommerce-rest-api-naquelize';

const axios = require('axios');
const rateLimit = require('axios-rate-limit');

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 50 })

const OMC = function (options) {
  this.endpoint = options.endpoint?.replace(/\/$/,'') || 'https://api.omnicommerce.app/v1';
  this.accessToken = options.accessToken || null;
  this.logger = options.logger || null;
  this.refreshTokenS = options.refreshTokenS || null;
  this.autoRefreshToken = options.autoRefreshToken || true;

  this.reqId = 0;

  this.init = () => {
    this.Entity = new Naquelize(this);
  };

  this.log = (level, message, data = null) => {
    const defaults = {
      m: 'OMC',
      s: 'API',
      t: null,
    };
    if (this.logger) {
      this.logger(
        Object.assign(
          { level, message },
          defaults,
          data
        )
      );
    }
  };

  this.operadoresCondicionales = ['gt','gte','lt','lte','like','notlike'];

  this.parseCondiciones = (cond) => {
    const condiciones = {};
    cond.forEach((item, i) => {
      switch(item.condition.toLowerCase()){
        case '>':
          condiciones[`${item.field}[gt]`] = item.value;
          break;
        case '>=':
          condiciones[`${item.field}[gte]`] = item.value;
          break;
        case '<':
          condiciones[`${item.field}[lt]`] = item.value;
          break;
        case '<=':
          condiciones[`${item.field}[lte]`] = item.value;
          break;
        case 'like':
          condiciones[`${item.field}[like]`] = item.value;
          break;
        case 'notlike':
          condiciones[`${item.field}[notlike]`] = item.value;
          break;
        case '!=':
          condiciones[`${item.field}[ne]`] = item.value;
          break;
        case '=':
          condiciones[`${item.field}`] = item.value;
          break;
        default:
          if(this.operadoresCondicionales.indexOf(item.condition) >= 0){
            condiciones[`${item.field}[${item.condition}]`] = item.value;
          }
          else{
            throw({ status : false, code : null, message : `No se entiende el operador condicional "${item.condition}"` });
          }
      }
    });
    return condiciones;
  };

  this.request = (dataIn) => {
    const omc = this;
    const reqId = ++this.reqId;
    const data = { ...dataIn };
    const promise = new Promise((resolve, reject) => {
      data.method = data.method || 'get';
      //console.log({data})
      data.url = omc.endpoint + (data.url.slice(0,1) !== '/' ? `/${data.url}` : data.url);

      data.headers = Object.assign({},
        (omc.accessToken && !data.url.match(/usuarios\/login/) ? { 'x-access-token' : omc.accessToken } : null),
        (data.headers ? data.headers : null),
        (data.opts && data.opts.tenantKey ? { 'x-tenant-key' : data.opts.tenantKey } : null));
        // console.log(data.headers)
      if(data.params){

        for (var key in data.params) {
          if (data.params.hasOwnProperty(key)) {
            data.url += (data.url.indexOf('?') > 0) ? `&${key}=${encodeURIComponent(data.params[key])}` : `?${key}=${encodeURIComponent(data.params[key])}`;
          }
        }
        delete data.params;
      }
      if(omc.logger){
        omc.log(`silly`, `Solicitud #${reqId} iniciando`, { me : data.method, u : data.url.replace(omc.endpoint,''), t : (data.opts && data.opts.tenantKey ? data.opts.tenantKey : null)});
      }
      http(data)
      .then(r => {
        if(omc.logger){
          omc.log(`silly`, `Solicitud #${reqId} finalizada (${(r.data.dataCount) ? r.data.dataCount + ' resultados' : 'exito'}).`, { t : (data.opts && data.opts.tenantKey ? data.opts.tenantKey : null) });
        }
        resolve({
          status : true,
          code : r.status,
          message : r.statusText,
          body : r.data
        });
      })
      .catch(e => {
        if(e.response){
          if(omc.autoRefreshToken && e.response.status == 401 && omc.refreshTokenS && (!dataIn.params || !dataIn.params.connectionTest)){
            if(omc.logger){
              omc.log(`debug`, `Solicitud #${reqId} - Chequeando conexión token...`)
            }
            omc.checkConnection()
            .then(rct => {
              omc.request(dataIn)
              .then(rrr => resolve(rrr))
              .catch(err => reject(err));
            })
            .catch(ect => {
              if(ect.code == 401){
                if(omc.logger){
                  omc.log(`debug`, `Solicitud #${reqId} - Intentando renovar un token...`)
                }
                omc.refreshToken()
                .then(rrt => {
                  if(omc.logger){
                    omc.log(`verbose`, `Solicitud #${reqId} - Token renovado. Reintendo solicitud.`)
                  }
                  omc.request(dataIn)
                  .then(rrr => resolve(rrr))
                  .catch(err => reject(err));
                })
                .catch(ert => {
                  if(omc.logger){
                    omc.log(`warn`, `Req: #${reqId} - Error renovando token.`)
                  }
                  reject({ status : false, code : 401, message : 'Unathorized', body : (e.response.data || {}) });
                });
              }
              else{
                omc.log(`warn`, `Req: #${reqId} - Error chequeando conexión.`)
                reject({ status : false, code : ect.response.status, message : ect.response.statusText, body : (ect.response.data || {}) });
              }
            })
          }
          else{
            reject({ 
              status : false,
              code : e.response.status,
              message : e.response.statusText,
              body : (e.response.data || {}),
              request : { id : reqId, method : (data.method || 'get'), uri : data.url, headers : data.headers }
            });
          }
        }
        else if(e.errno == 'ENOTFOUND' && e.syscall == 'getaddrinfo'){
          reject({ status : false, code : null, message : `Cannot connect endpoint ${omc.endpoint}`, body : {}});
          if(omc.logger){
            omc.log(`error`, `Request #${reqId} falló: Falló la conexión con el endpoint: ${omc.endpoint}`);
          }
        }
        else if(e.code == 'ERR_TLS_CERT_ALTNAME_INVALID'){
          reject({ status : false, code: null, message : `SSL error endpoint ${omc.endpoint}`, body : {}});
          if(omc.logger){
            omc.log(`error`, `Request #${reqId} falló: El nombre en el certificado no concuerda con el endpoint: ${omc.endpoint}`);
          }
        }
        else if(e.code == 'EHOSTDOWN'){
          reject({ status : false, code: null, message : `No se pudo establecer la conexión "${e.code}"`, body : {}});
          if(omc.logger){
            omc.log(`error`, `Request #${reqId} falló: No se pudo establecer la conexión`);
          }
        }
        else if(e.code == 'ECONNREFUSED'){
          reject({ status : false, code: null, message : `No se pudo establecer la conexión "${e.code}"`, body : {}});
          if(omc.logger){
            omc.log(`error`, `Request #${reqId} falló: No se pudo establecer la conexión`);
          }
        }
        else{
          reject({ status : false, code: null, message : `Error no manejado`, body : {}});
          console.log(e)
          if(omc.logger){
            omc.log(`error`, `Request #${reqId} falló: Ha ocurrido un error`);
          }
        }
        console.log("request error", e)
      });
    });
    return promise;
  };

  this.checkConnection = () => {
    let omc = this;
    let promise = new Promise(function(resolve, reject) {
      omc.request({ url : '/usuarios/me', params : { connectionTest : 1 } })
      .then(r => resolve(r))
      .catch(e => reject(e));
    });
    return promise;
  };

  this.login = (email, clave, refreshToken) => {
    let omc = this;
    let promise = new Promise(function(resolve, reject) {
      omc.request({
        method : 'post',
        url : '/usuarios/login',
        data : (refreshToken ? { refreshToken : refreshToken } : { email : email, clave : clave })
      })
      .then(r => {
        omc.accessToken = r.body.token;
        omc.refreshTokenS = r.body.refreshToken;
        const redir = new URL(window.location.href).searchParams.get('redir')
        resolve({...r, redir});
      })
      .catch(e => reject(e));
    });
    return promise;
  };

  this.refreshToken = (refreshToken) => {
    refreshToken = refreshToken || this.refreshTokenS;
    return this.login(null, null, refreshToken)
  };

  this.paramsProcess = (params) => {
    if(!params){
      return {}
    }
    let paramsProcessed = { ...params};
    if(params.where){
      Object.assign(paramsProcessed, this.parseCondiciones(params.where))
      delete paramsProcessed.where;
    }
    if(params.include){
      paramsProcessed.include = params.include.join(',');
    }
    if(params.fields){
      paramsProcessed.fields = params.fields.join(',');
    }
    if(params.sort){
      paramsProcessed.sort = params.sort.join(',');
    }
    return paramsProcessed;
  };

  this.get = (endpoint, params, opts = {}) => {
    params = this.paramsProcess(params);
    var omc = this;
    let promise = new Promise(function(resolve, reject) {
      let followPages = 1;
      let originalPage = params.page || 1;
      if(typeof params.followPages !== 'undefined'){
        followPages = (!isNaN(params.followPages) && (params.followPages !== true)) ? params.followPages : 50;
        delete params.followPages;
      }
      omc.request({
        url : endpoint,
        params : params,
        opts : opts,
      })
      .then(r => {
        var results = r;
        if(followPages > 1 && Array.isArray(r.body.data) && !r.body._links){
          reject({ status: false, message: 'El endpoint no permite paginado automático.'})
          if(omc.logger){
            omc.log(`warn`, `El endpoint no permite paginado automático`);
          }
        }
        else if(followPages > 1 && r.body._links && r.body._links.nextPage){
          let req = [];
          delete results.body._links;
          delete results.body.page;

          let maxPage = Math.ceil(r.body.total / r.body.dataCount) + 1;
          maxPage = (maxPage < (originalPage + followPages)) ? maxPage : originalPage + followPages;

          for(var page = (originalPage + 1); page < maxPage; page++){
            req.push(omc.request({
              url : endpoint,
              params : Object.assign({}, params, { page : page}),
              opts : opts,
            }))
          }
          Promise.all(req)
          .then(reqs => {
            reqs.forEach((r, i) => {
              results.body.dataCount += r.body.dataCount;
              results.body.data = results.body.data.concat(r.body.data);
            });
            results.body.fromPage = originalPage;
            results.body.toPage = (page - 1);
            resolve(results);
          })
          .catch(e => {
            reject(e);
          })
        }
        else{
          resolve(results)
        }
      })
      .catch(e => reject(e));
    });
    return promise;
  };

  this.post = (endpoint, data, params, opts = {}) => {
    return this.request({
      url : endpoint,
      method : 'post',
      data : data,
      params : params,
      opts : opts,
    })
  };

  this.patch = (endpoint, data, params, opts = {}) => {
    return this.request({
      url : endpoint,
      method : 'patch',
      data : data,
      params : params,
      opts : opts,
    })
  };

  this.put = (endpoint, data, params, opts = {}) => {
    return this.request({
      url : endpoint,
      method : 'put',
      data : data,
      params : params,
      opts : opts,
    })
  };

  this.delete = (endpoint, params, opts = {}) => {
    return this.request({
      url : endpoint,
      method : 'delete',
      params : params,
      opts : opts,
    })
  };


  this.Entity = {}


  this.init();

  return this;

};

export default OMC;
