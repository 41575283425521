/* eslint-disable linebreak-style */
import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import omc from 'src/utils/omnicommerce/omc-ready-to-use';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  /*
  loginWithEmailAndPassword = (email, clave, refreshToken) => new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_URL + '/usuarios/login', { email, clave })
      .then((response) => {
        if (response.data.status) {
          this.setSession(response.data.token, response.data.refreshToken);
          resolve(response.data.usuario);
        } else {
          reject(response.data.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
  renewToken = (refreshToken) => new Promise((resolve, reject) => {
    if(!refreshToken && this.getRefreshToken()){
      refreshToken = this.getRefreshToken()
    }
    if(refreshToken){
      axios.post(process.env.REACT_APP_API_URL + '/usuarios/login', { refreshToken : refreshToken })
        .then((response) => {
          if (response.data.status) {
            this.setSession(response.data.token, response.data.refreshToken);
            resolve(response.data.usuario);
          } else {
            reject(response.data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
    else{
      reject({ status : false, message : 'Necesitas un refresthToken para loguearte vía Token'})
    }
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get(process.env.REACT_APP_API_URL + '/usuarios/me')
      .then((response) => {
        if (response.data.status) {
        resolve(response.data.data);
        } else {
          reject(response.data.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
  */

  loginWithEmailAndPassword = (email, clave, refreshToken) => new Promise((resolve, reject) => {
    omc.login(email, clave)
      .then((response) => {
        if (response.body.status) {
          this.setSession(response.body.token, response.body.refreshToken);
          omc.get('/usuarios/me/configs')
            .then((responseConfig) => {
              if (responseConfig.body.status) {
                this.setSessionConfig(responseConfig.body.data);
              } else {
                //reject(responseConfig.body.message);
              }
            })
            .catch((error) => {
              //reject(error.body);
              console.log(error);
            })
            .finally(() => {
              if(response.redir){
                const newHref = decodeURIComponent(response.redir.replace('[RT]',response.body.refreshToken));
                document.location.href = `${newHref}${newHref.includes('?') ? '&' : '?'}login=true`;
              }
              else{
                resolve(response.body.usuario);
              }
            });

        } else {
          reject(response.body);
        }
      })
      .catch((error) => {
        reject(error.body);
      });
  })

  renewToken = (refreshToken) => new Promise((resolve, reject) => {
    if(!refreshToken && this.getRefreshToken()){
      refreshToken = this.getRefreshToken()
    }
    if(refreshToken){
      omc.login(null, null, refreshToken)
        .then((response) => {
          if (response.body.status) {
            this.setSession(response.body.token, response.body.refreshToken);
            resolve(response.body.usuario);
          } else {
            reject(response.body);
          }
        })
        .catch((error) => {
          reject(error.body);
        });
    }
    else{
      reject({ status : false, message : 'Necesitas un refresth token para loguearte vía Token'})
    }
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    omc.get('/usuarios/me')
      .then((response) => {
        if (response.body.status) {
        resolve(response.body.data);
        } else {
          reject(response.body.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken, refreshToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      if(refreshToken){
        localStorage.setItem('refreshToken', refreshToken);
      }
      axios.defaults.headers.common['x-access-token'] = `${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      delete axios.defaults.headers.common['x-access-token'];
    }
  }

  setSessionConfig = (config) => {
    if (config) {
      localStorage.setItem('config', config);
    } else {
      localStorage.removeItem('config');
    }
  }

  getConfig = () => localStorage.getItem('config');

  getAccessToken = () => localStorage.getItem('accessToken');

  getRefreshToken = () => localStorage.getItem('refreshToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
