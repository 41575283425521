'use strict'

const Naquelize = function (omc){

  let defaults = {
    findAllLimit : 1000,
    findAllApiLimit : 200,
  };

  let mapping = {
    Cliente : {
      uri : '/clientes'
    },
    Producto : {
      uri : '/catalogo/productos'
    },
    ProductoVariante : {
      uri : '/catalogo/producto_variantes'
    },
    ProductoCategoria : {
      uri : '/catalogo/productos/categorias'
    },
    Atributo : {
      uri : '/catalogo/atributos'
    },
    AtributoValor : {
      uri : '/catalogo/atributo_valores'
    },
    ProductoAtributo : {
      uri : '/catalogo/producto_atributos'
    },
    ProductoImagen : {
      uri : '/catalogo/producto_imagenes'
    },
    ProductoPrecio : {
      uri : '/catalogo/producto_precios'
    },
    ProductoStock : {
      uri : '/catalogo/producto_stocks'
    },
    Pedido : {
      uri : '/pedidos'
    },
    Conexion : {
      uri : '/conexiones'
    },
    ConexionParametro : {
      uri : '/conexion_parametros'
    },
    Match : {
      uri : '/matches'
    }
  };

  let parseWhere = (w) => {
    let pw = [];
    for (var c in w) {
      if (w.hasOwnProperty(c)) {
        pw.push({ field : c, condition : '=', value : w[c] })
      }
    }
    return pw;
  };

  let parseOrder = (o = []) => {
    let po = [];
    o.forEach((item, i) => {
      if(!Array.isArray(item)){
        throw('options.order: Debe ser un Array')
      }
      else{
        if(item.length === 2 && item[1].toUpperCase() === 'DESC'){
          po.push('-' + item[0]);
        }
        else{
          po.push(item[0]);
        }
      }
    });
    return po;
  };

  let findOne = async (m,o, opts = {}) => {
    var naq = this;
    let promise = new Promise(function(resolve, reject) {
      findAll(m,o, opts)
      .then(r => resolve(r.length ? r[0] : null))
      .catch(e => reject(e));
    });
    return promise;
  };

  let findAll = async (m,o, opts = {}) => {
    var naq = this;

  // Options:

    // noRecall es una opción interna (o forzable) para no hacer el segundo llamado y obtener atributos faltantes en el método listar.
    // Por defect está activada, si el request falla con result 'invalidAttributes' se reintentará con esta opción = false.
    var noRecall = (typeof opts.noRecall !== 'undefined') ? opts.noRecall : (o.attributes ? true : false);

    // opts.justIds se utiliza para sólo obtener ids, útil en queries internas before update y findOrCreate.


    // Se utiliza la opción global de límite o la indicada en el objeto query.
    var findAllLimit = o.limit || defaults.findAllLimit;


    let promise = new Promise(function(resolve, reject) {
      omc.get(`${mapping[m].uri}`, Object.assign({},
        {
        where : parseWhere(o.where),
        },
      (!noRecall || opts.justIds ? { include :[null] } : (o.include ? {include : o.include } : null)),
      (!noRecall || opts.justIds ? { fields :['id'] } : (o.attributes ? { fields : o.attributes } : null)),
      (o.order ? { sort : parseOrder(o.order) } : null),
      { limit : defaults.findAllApiLimit, followPages : Math.ceil(findAllLimit/defaults.findAllApiLimit) },
    ), opts)
      .then(r => {
        if(noRecall){
          delete r.body.data._links;
          resolve(r.body.data);
        }
        else if(r.body.dataCount){
          let data = [];
          let dataPromises = [];
          r.body.data.forEach((item, i) => {
            dataPromises.push(omc.get(`${mapping[m].uri}/${item.id}`,
                Object.assign({},
                  { include : o.include || [null] },
                  (o.attributes ? { fields : o.attributes } : null),
                ), opts
              )
            )
          });

          Promise.all(dataPromises)
          .then(r2 => {
            r2.forEach((item, i) => {
              data.push(item.body.data);
            });
            resolve(data.map(x => {
              delete x._links;
              return x;
            }))
          })
          .catch(e => reject(e))
        }
        else{
          resolve([])
        }
      })
      .catch(e => {
        // console.log(e)
        if(e.body && (e.body.result === 'invalid-attributes' || e.body.result === 'invalid-includes') && !opts.noRecallTry){
          findAll(m, o, Object.assign({}, opts, { noRecall : false, noRecallTry : true }))
          .then(r2 => resolve(r2))
          .catch(e2 => reject(e2));
        }
        else{
          reject(e)
        }
        // reject(e)
      })
    });
    return promise;
  };

  let findOrCreate = async (m, o, opts = {}) => {
    var naq = this;

  // Options
    var returnElement = opts.returnElement || true;

    let promise = new Promise(function(resolve, reject) {
      if(!o.where){
        reject('Necesitas indicar al menos una condicion!');
        return;
      }
      if(returnElement){
        findOne(m, { where : o.where }, opts)
        .then(r => {
          if(r){
            resolve([r, false]);
          }
          else{
            create(m, Object.assign({}, o.where, o.defaults), opts)
            .then(r2 => resolve([r2, true]))
            .catch(e => reject(e));
          }
        })
        .catch(e => reject(e));
      }
      else{
        findOne(m, { where : o.where }, opts)
        .then(r => {
          if(r){
            resolve([true, false]);
          }
          else{
            create(m, Object.assign({}, o.where, o.defaults), opts)
            .then(r2 => resolve([true, true]))
            .catch(e => reject(e));
          }
        })
        .catch(e => reject(e));
      }
    });
    return promise;
  };

  let create = async (m, o, opts = {}) => {
    var naq = this;

  // Options
    var returnElement = opts.returnElement || true;

    let promise = new Promise(function(resolve, reject) {
      omc.post(mapping[m].uri, o, {}, opts)
      .then(r => {
        if(returnElement){
          omc.get(`${mapping[m].uri}/${r.body.id}`, { include : [null]}, opts)
          .then(r2 => {
            delete r2.body.data._links;
            resolve(r2.body.data)
          })
          .catch(e => reject(e))
        }
        else{
          resolve(true);
        }
      })
      .catch(e => reject(e))
    });
    return promise;
  };

  let update = async (m, d, o, opts = {}) => {
    var naq = this;
    let promise = new Promise(function(resolve, reject) {
      findAll(m, o, Object.assign({}, opts, { justIds : true }))
      .then(r => {
        let patched = [];
        r.forEach((item, i) => {
          patched.push(omc.patch(`${mapping[m].uri}/${item.id}`, d, {}, opts));
        });
        Promise.all(patched)
        .then(r2 => resolve([r2.length]))
        .catch(e => reject(e));
      })
      .catch(e => reject(e));
    });
    return promise;
  };

  let destroy = async (m, o, opts) => {
    var naq = this;
    let promise = new Promise(function(resolve, reject) {
      findAll(m, o, Object.assign({}, opts, { justIds : true }))
      .then(r => {
        let deleted = [];
        r.forEach((item, i) => {
          deleted.push(omc.delete(`${mapping[m].uri}/${item.id}`, opts));
        });
        Promise.all(deleted)
        .then(r2 => resolve(r2.length))
        .catch(e => reject(e));
      })
      .catch(e => reject(e));
    });
    return promise;
  }


  let Entity = {};

  for (var m in mapping) {
    if (mapping.hasOwnProperty(m)) {
      let n = m;
      Entity[n] = {
        findOne : async (o, opts) => findOne(n, o, opts),
        findAll : async (o, opts) => findAll(n, o, opts),
        findOrCreate : async (o, opts) => findOrCreate(n, o, opts),
        create : async (o, opts) => create(n, o, opts),
        update : async (d, o, opts) => update(n, d, o, opts),
        destroy : async (o, opts) => destroy(n, o, opts)
      }
    }
  }

  return Entity;

};

export default Naquelize;
