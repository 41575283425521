/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ConfettiRaw from 'react-confetti';
import useWindowSize from 'src/hooks/useWindowSize';

const MAX_CONFETTI = 180 * 4;
const TOTAL_TIME = 5000;

function Confetti({ activar }) {

  const { width, height } = useWindowSize();

  const [numberOfPieces, setNumberOfPieces] = React.useState(0);

  useEffect(() => {
    if (activar) {
      setTimeout(() => {
        setNumberOfPieces(MAX_CONFETTI);
      }, 50);
      setTimeout(() => {
        setNumberOfPieces(0);
      }, TOTAL_TIME);
    } else {
      setNumberOfPieces(0);
    }
  }, [activar]);

  return (
    <ConfettiRaw
      width={width}
      height={height}
      numberOfPieces={numberOfPieces}
      gravity={0.25}
      initialVelocityY={{ min: -20, max: 20 }}
      initialVelocityX={{ min: -20, max: 20 }}
      confettiSource={{
        w: 200,
        h: 200,
        x: (width / 2) - 100,
        y: (height / 2) - 100,
      }}
    />
  );
};

Confetti.propTypes = {
  activar: PropTypes.any
};

export default Confetti;
