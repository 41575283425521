import React from 'react';
import { logout } from 'src/actions/accountActions';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';


function Logout() {
  const dispatch = useDispatch();
  dispatch(logout());
  const queryParameters = new URLSearchParams(window.location.search);
  const redir = queryParameters.get('redir');
  if (redir) {
    window.location.href = `${redir}?logout=true`;
  } else {
    return <Redirect to="/login" />;
  }
}

export default Logout;