/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  // Briefcase as BriefcaseIcon,
  // Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  // Lock as LockIcon,
  // UserPlus as UserPlusIcon,
  // Shield as ShieldIcon,
  // AlertCircle as AlertCircleIcon,
  // Trello as TrelloIcon,
  // User as UserIcon,
  // Layout as LayoutIcon,
  // Edit as EditIcon,
  // DollarSign as DollarSignIcon,
  // Mail as MailIcon,
  // MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Archive as ArchiveIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  MessageCircle as MessageCircleIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Reportes',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/dashboard'
      },
      {
        title: 'Archivos',
        icon: ArchiveIcon,
        href: '/app/archivos'
      },
      // {
      //   title: 'Dashboard Alternative',
      //   icon: BarChartIcon,
      //   href: '/app/reports/dashboard-alternative'
      // }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Usuarios',
        icon: UsersIcon,
        role: 'Administrador',
        href: '/app/usuarios',
        items: [
          {
            title: 'Listar Usuarios',
            href: '/app/usuarios'
          },
          // {
          //   title: 'View Customer',
          //   href: '/app/management/customers/1'
          // },
          // {
          //   title: 'Edit Customer',
          //   href: '/app/management/customers/1/edit'
          // }
        ]
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/clientes',
        items: [
          {
            title: 'Listar Clientes',
            href: '/app/clientes'
          },
          // {
          //   title: 'View Customer',
          //   href: '/app/management/customers/1'
          // },
          // {
          //   title: 'Edit Customer',
          //   href: '/app/management/customers/1/edit'
          // }
        ]
      },
      {
        title: 'Productos',
        icon: ShoppingCartIcon,
        href: '/app/catalogo/productos',
        items: [
          {
            title: 'Listar Productos',
            href: '/app/catalogo/productos'
          },
          // {
          //   title: 'Create Product',
          //   href: '/app/management/products/create'
          // }
        ]
      },
      {
        title: 'Pedidos',
        icon: FolderIcon,
        href: '/app/pedidos',
        items: [
          {
            title: 'Listar Pedidos',
            href: '/app/pedidos'
          },
          {
            title: 'Crear Pedido',
            href: '/app/pedidos/create'
          },
          // {
          //   title: 'View Order',
          //   href: '/app/management/orders/1'
          // }
        ]
      },
      // {
      //   title: 'Invoices',
      //   icon: ReceiptIcon,
      //   href: '/app/management/invoices',
      //   items: [
      //     {
      //       title: 'List Invoices',
      //       href: '/app/management/invoices'
      //     },
      //     {
      //       title: 'View Invoice',
      //       href: '/app/management/invoices/1'
      //     }
      //   ]
      // }
    ]
  },
  {
    subheader: 'Conectividad',
    items: [
      {
        title: 'Conexiones',
        href: '/app/conexiones',
        icon: ShareIcon,
        items: [
          {
            title: 'Listar Conexiones',
            href: '/app/conexiones'
          },
          // {
          //   title: 'Feed',
          //   href: '/app/social/feed'
          // }
        ]
      },
      // {
      //   title: <>
      //     Chat
      //     <Chip
      //       size="small"
      //       label="New"
      //       style={{
      //         marginLeft: '5px',
      //         backgroundImage: 'linear-gradient(90deg, #0078d4, #c218ec)',
      //         color: '#fff',
      //         padding: '0',
      //         fontSize: '12px',
      //         borderRadius: '15px'
      //       }}
      //     />
      //   </>,
      //   //chat icon
      //   icon: MessageCircleIcon,
      //   href: '/chat/',
      //   newScreen: true
      // },
      /*{
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },*/
      {
        title: <>
          Chat
          <Chip
            size="small"
            label="New"
            style={{
              marginLeft: '5px',
              backgroundImage: 'linear-gradient(90deg, #0078d4, #c218ec)',
              color: '#fff',
              padding: '0',
              fontSize: '12px',
              borderRadius: '15px'
            }}
          />
        </>,
        //chat icon
        icon: MessageCircleIcon,
        href: '/app/chat',
        // newScreen: true
      }
      // {
      //   title: 'Chat',
      //   href: '/app/chat',
      //   icon: MessageCircleIcon,
      //   info: () => (
      //     <Chip
      //       color="secondary"
      //       size="small"
      //       label="Updated"
      //     />
      //   )
      // },
      /*
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon
      },*/
    ]
  },
  /*{
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      },
      {
        title: 'Login: Guest Protected',
        href: '/login',
        icon: ShieldIcon
      },
      {
        title: 'Register: Guest Protected',
        href: '/register',
        icon: ShieldIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    href: '/app/pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          },
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }*/
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        newScreen={item.newScreen}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    // cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Typography
              variant="h5"
              color="textSecondary"
            >
              {`${user.nombre} ${user.apellido || ''}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => {
            /*
            const filtrarItems = (items) => {
              return items.map((item) => {
                if (item.role && !user.rol.nombre === item.role) {
                  return null;
                }
                if (item.items) {
                  item.items = filtrarItems(item.items);
                }
                return items;
              }).filter(Boolean);
            }
            */

            const filtrarItems = (items) => {
              return items.filter((item) => !item.role || user.rol.nombre === item.role)
            }

            config.items = filtrarItems(config.items);
            
            return <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          })}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              En qu&eacute; podemos ayudarte?
            </Typography>
            <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-subtitle1 MuiTypography-colorSecondary"
             href="https://cdn.nmg.systems/tv/windows/Quick-support.exe"
             target="_self"
             rel="noopener noreferrer"
             >
             Asistencia r&aacute;pida
             </a><br />
            <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-subtitle1 MuiTypography-colorSecondary"
             href="https://wa.me/5491152394056"
             target="_blank"
             rel="noopener noreferrer"
             >
             Enviar sugerencias
             </a>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;