import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import OneSignal, { useOneSignalSetup } from 'react-onesignal';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const oneSignalEvents = [
  {
    listener: 'once',
    event: 'subscriptionChange',
    callback: (isSubscribed) => {
      if(isSubscribed){
        console.log('Usuario suscrito a OS push service')
      }
    },
  },
];

OneSignal.initialize('3401494b-d2c5-403d-af71-9b91bdd692b8', {
  allowLocalhostAsSecureOrigin : true,
  persistNotification : true,
  autoResubscribe : true,
  notifyButton: {
    enable: false,
    showCredit: false,
  }
}, oneSignalEvents)

function App() {
  useStyles();

  const { settings } = useSettings();
  const account = useSelector((state) => state.account);
  const [isTagged, setTagged] = React.useState(false);
  const [isOneSignalActive, setOneSignalActive] = React.useState(false);

  if(account.user && !isTagged && isOneSignalActive){
    console.log('Tagged user in Push Service')
    OneSignal.sendTags({ tenantKey : account.user.tenantKey, usuario : account.user.usuario })
    // OneSignal.deleteTag('plataforma')
    setTagged(true);
  }

  useOneSignalSetup(() => {
    setOneSignalActive(true);
  });

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                <SettingsNotification />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
