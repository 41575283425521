/* eslint-disable linebreak-style */
import axios from 'src/utils/axios';
import moment from 'moment';
import omc from 'src/utils/omnicommerce/omc-ready-to-use';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';

export function getNotifications() {
  const request = omc.get('/usuarios/me/notificaciones?archivada=false');

  return (dispatch) => {
    request
      .then((response) => {
        const dataParsed = response.body.data.map((x) => {
          const b = x
          const dateArray = b.fechaHora.split('T')[0].split('-');
          const hourArray = x.fechaHora.split('T')[1].split('.')[0].split(':');
          b.fechaHoraFriendly = `${dateArray[2]}/${dateArray[1]} ${hourArray[0]}:${hourArray[1]}`;
          b.fechaHoraCompleteFriendly = `${dateArray[2]}/${dateArray[1]} ${hourArray[0]}:${hourArray[1]}`;
          if (b.cantidad) {
            b.ultimaFechaHora = b.ultimaFechaHora
              ? moment(b.ultimaFechaHora).add(3, 'hours').format()
              : moment(b.fechaHora).add(3, 'hours').format();
            // let dateArray2 = b.ultimaFechaHora.split('T')[0].split('-');
            // let hourArray2 = b.ultimaFechaHora.split('T')[1].split('.')[0].split(':');
            // b.fechaHoraLastFriendly =
            // `${dateArray2[2]}/${dateArray2[1]} ${hourArray2[0]}:${hourArray2[1]}`;
            b.fechaHoraLastNotsoFriendly = moment(b.ultimaFechaHora).format('D MMMM YYYY, h:mm:ss a')
            b.fechaHoraLastFriendly = moment(b.ultimaFechaHora || b.fechaHora).fromNow();
          }
          if (b.metadata) {
            try {
              b.metadata = JSON.parse(b.metadata);
              b.metadataFriendly = '';
              for (const d in b.metadata) {
                if (b.metadata.hasOwnProperty(d)) {
                  b.metadataFriendly += `\n - ${d}: ${b.metadata[d]}`;
                }
              }
            } catch (e) {
              // Silence
            }
          }
          return b;
        });
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: { notifications: dataParsed }
        });
      })
      .catch((e) => {
        console.warn('Error obteniendo las notificaciones', e)
      });
  };
}

export const ARCHIVE_NOTIFICATION = '@notifications/archive-notifications';

export function archiveNotification(idNotification) {
  const request = omc.patch(`/usuarios/me/notificaciones/${idNotification}`, { archivada: "true" });
  return (dispatch) => {
    dispatch({
      type: ARCHIVE_NOTIFICATION,
      payload: { idNotification, archivada: true}
    });
    request
      .catch((e) => {
        dispatch({
          type: ARCHIVE_NOTIFICATION,
          payload: { idNotification, archivada: false}
        });
        console.warn('Error obteniendo las notificaciones', e)
      });
  };
}

export const MARCAR_NOTIFICATION = '@notifications/marcar-notifications';

export function marcarNotification(idNotification, valorNuevoVista) {
  const request = omc.patch(`/usuarios/me/notificaciones/${idNotification}`, { vista: (valorNuevoVista).toString() });

  return (dispatch) => {
    dispatch({
      type: MARCAR_NOTIFICATION,
      payload: { idNotification, vista: valorNuevoVista}
    });
    request
      .catch((e) => {
        //TODO handle error mostrar mensaje de error
        dispatch({
          type: MARCAR_NOTIFICATION,
          payload: { idNotification, vista: !valorNuevoVista}
        });
        console.warn('Error obteniendo las notificaciones', e)
      });
  };
}
